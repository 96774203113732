import React from 'react';
import Baking from "../media/images/baking.jpg";

const G = window.glob;
const myName = 'About';

const About = class extends React.Component {
	constructor(props) {
		super(props);
		this.uid = G.generateUid(myName);
	}
	render() {
		return(
			<div className={myName} id={this.uid}>
				<img src={Baking} alt="Me" className={"MainSideImage"}/>
				<h3 className={"mot"} lang={"it"}>
					«Vai al supermercato e prendi due litri di latte. Se ci sono le uova prendine sei.»<br />
					— Probabilmente tornerò con sei litri di latte
				</h3>
				<h3 className={"mot"} lang={"en"}>
					«Go to the supermarket and buy two cartons of milk. If they've eggs, buy six.»<br />
					— I’ll probably come back with six cartons of milk
				</h3>
				<div lang={"it"}>
					<p>
						Nato e cresciuto a Roma, ho vissuto a Pisa dove ho studiato Scienze dell’Informazione,
						poi di nuovo a Roma e ora a Cambridge.
					</p>
					<p>
						Alcune tappe professionali particolarmente importanti:
					</p>
				</div>
				<div lang={"en"}>
					<p>
						Born in Rome, I lived in Pisa while studying Information Technology, then again
						in Rome, now settled in Cambridge.
					</p>
					<p>
						Most relevant steps in my professional experience:
					</p>
				</div>
				<ul>
					<li>
						Free-lance IT consultant, graphic and web design
					</li>
					<li>
						System administrator in FONSPA (Italian bank institute)
					</li>
					<li>
						IT support and development for GN-Research
					</li>
					<li>
						Many years working for the FAO of the UN, as professional consultant
					</li>
					<li>
						CTO for Hermes-Bay
					</li>
				</ul>
				<p lang={"it"}>
					Mi interesso di informatica, cucina e panificazione.<br/>
					Per quel che riguarda l'informatica, i miei ambiti sono:
				</p>
				<p lang={"en"}>
					My interests are in IT, cooking and baking.<br/>
					About IT, my fields of expertise cover:
				</p>
				<ul>
					<li>
						Systems architecture and software design
					</li>
					<li>
						Web development, especially focused on front-end technologies
					</li>
					<li>
						Information treatment and data mangling (XML, XSLT, Perl)
					</li>
					<li>
						*nix system administration, DBA
					</li>
				</ul>
			</div>
		);
	}
}

export default About;