import React from 'react';
import './App.css';
import Home from "./Home.mjs";
import TabBar from "./TabBar.mjs";
import About from "./About.mjs";
import Tech from "./Tech.mjs";

const G = window.glob;

const App = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: G.getCurrentPage(),
			lang: G.getLanguage()
		};
		this.uid = G.generateUid('App');
		this.route = this.route.bind(this);
	}
	componentDidMount() {
		G.register("currentPage", (m) => {
			this.setState({ page: m });
		}, this.uid);
		G.register( 'Prefs', p => {
			if ( p?.language && p.language !== this.state.lang ) this.setState({ lang: p.language });
		})
	}
	componentWillUnmount() {
		G.deregister(this.uid);
	}
	route() {
		switch ( this.state.page?.name) {
			case 'About':	return <About params={ this.state.page?.params } />;
			case 'Tech':	return <Tech params={ this.state.page?.params } />;
			default:		return <Home params={ this.state.page?.params} />;
		}
	}
	render() {
		return(
			<div className="App" id={this.uid} lang={this.state.lang}>
				<TabBar />
				<div className="AppMainContent" key={ this.state.page?.name }>
					{ this.route() }
				</div>
			</div>
		)
	}
}

export default App;
