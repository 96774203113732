import React from 'react';
import "./TabBar.css";
import {Nav, NavItem, NavLink} from "reactstrap";
import TextLabel from "../Controls/TextLabel.mjs";
import LanguageSwitcher from "../Controls/LanguageSwitcher.mjs";

const G = window.glob;
const myName = 'TabBar';

const tabs = ['Home', 'About','Tech'];

const TabBar = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { page: G.getCurrentPage()?.name };
		this.uid = G.generateUid(myName);
	}
	componentDidMount() {
		G.register( 'currentPage', mo => {
			if ( mo?.name && mo.name !== this.state.page ) this.setState({ page: mo?.name });
		}, this.uid);
	}
	componentWillUnmount() {
		G.unregister('currentPage', this.uid);
	}
	render() {
		return(
			<div className={myName} id={this.uid}>
				<Nav tabs>
					{ tabs.map( (t,i) => (
						<NavItem key={i}>
							<NavLink
								className={ this.state.page === t ? "active" : "inactive"}
								onClick={ () => { G.setCurrentPage(t) } }
							>
								<TextLabel value={"tab-"+t}/>
							</NavLink>
						</NavItem>
					)) }
				</Nav>
				<LanguageSwitcher />
			</div>
		);
	}
}

export default TabBar;