const G = window.glob;

const appLib = {};

appLib.link = ( options = {}  ) => {
	const d = options.domain ?? window.location.hostname.replace(/^.*\.([^.]+\.[^-]+)$/, "$1");
	const type = options.type ?? 'm';
	const detail = options.detail ?? ( type === 'm' ? 'marco' : '');
	let uri = options.uri ?? '';
	if ( uri === '') {
		switch (type) {
			case 'm':
				uri = type+'ailto:'+detail+'@'+d;
				if ( options.subject ) uri += '?subject=' + encodeURIComponent(options.subject);
				break;
			default: uri = window.location.protocol + '//'+detail+'.'+d;
		}
	}
	const a = window.document.createElement("a");
	a.href = uri;
	if ( options.target ) a.target = options.target;
	a.style.display = 'none';
	window.document.body.appendChild(a);
	a.click();
	window.document.body.removeChild(a);
};

export default appLib;