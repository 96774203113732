import React from 'react';
import HomeImage from "../media/images/marco11-640px.jpg";
import "./Home.css";
import {Button, ButtonGroup} from "reactstrap";
import MailIcon from "../media/images/MailIcon.mjs";
import TextLabel from "../Controls/TextLabel.mjs";
import ImIcon from "../media/images/ImIcon.mjs";
import appLib from "../libs/appLib.mjs";

const G = window.glob;

const Home = class extends React.Component {
	constructor(props) {
		super(props);
		this.uid = G.generateUid('Home');
	}
	render() {
		return(
			<div className={"Home"} id={this.uid}>
				<img src={HomeImage} alt="Me" className={"MainSideImage"}/>
				<div lang="it">
					<h3 className={"mot quote"}>
						Salve, siete arrivati sulla homepage di un sito personale.
					</h3>
					<p>
						Il mio nome è <strong>Marco Balestra</strong>, sono un consulente informatico da molti anni.
					</p>
					<p>
						Questa è la mia pagina personale (<em>crossbow</em> significa <em>balestra</em>, in inglese),
						ciononostante dato il valore che do alla mia privacy personale qui non troverete dettagli
						della mia vita privata, solo qualche <a className={"link"} href="#!/About">informazione professionale</a> – e neanche tante.
					</p>
					<p>
						Di seguito i canali con cui è possibile contattarmi:
					</p>
				</div>
				<div lang="en">
					<h3 className={"mot quote"}>
						Hello, you landed on a personal website homepage.
					</h3>
					<p>
						My name is <strong>Marco Balestra</strong>, I’m an Information Technology consultant with many
						years of
						experience.
					</p>
					<p>
						This is my own personal space (<em>crossbow</em> translates into <em>balestra</em>, in Italian),
						however, given the value I give to my personal privacy, you will not find here
						details of my private life, only some <a className={"link"} href={"#!/About"}>professional
						information</a> – and not much.
					</p>
					<p>
						Some channels you could use to contact me follow:
					</p>
				</div>
				<div align={"center"}>
					<ButtonGroup>
						<Button
							onClick={() => {
								appLib.link({
									uri: 'https://signal.me/#eu/HcRvzYsaEYWG9a5BlAJ052GpqfEE9RGdkBHOWQt-cm00zFyiFTNEU6XkRyzBKQwn',
									target: "t-me"
								})
							}}
							color={"primary"}
							className={"btn-icon"}
							title={ G.label('SignalMe') }
						>
							<i>
								<ImIcon/>
							</i>
							<b>
								<TextLabel value={"Signal"}/>
							</b>
						</Button>
						<Button
							onClick={() => {
								appLib.link({subject: G.label("MailSubject")})
							}}
							color={"warning"}
							className={"btn-icon"}
							title={ G.label('MailMe') }
						>
							<i>
								<MailIcon/>
							</i>
							<b>
								<TextLabel value={"E-mail"}/>
							</b>
						</Button>
						<Button
							onClick={() => {
								appLib.link({uri: 'https://t.me/crossbow', target: "t-me"})
							}}
							color={"primary"}
							className={"btn-icon"}
							title={ G.label('TelegramMe') }
						>
							<i>
								<ImIcon/>
							</i>
							<b>
								<TextLabel value={"Telegram"}/>
							</b>
						</Button>
					</ButtonGroup>
					<p/>
				</div>
				<p lang="it">
					<em>
						<strong>N.B.</strong>: accetto esclusivamente contatti di tipo professionale o personale.<br/>
						Qualunque altro tipo di contatto verrà ignorato e bloccato.<br />
						Tutto lo spam viene segnalato ed eliminato.
					</em>
				</p>
				<p lang="en">
					<em>
						<strong>Please note</strong>: just contact me for professional or personal reason.<br/>
						Whatever else type of contact is automatically slashed and banned.<br />
						Every spam is reported and deleted.
					</em>
				</p>
			</div>
		);
	}
}

export default Home;